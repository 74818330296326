//
// Theme style
//

// Mode
$mode: default;

// Initialize
@import 'init';

// Components
@import './core/components/components';
@import 'components/components';

// Layout
@import 'layout/layout';
@import './core/layout/docs/layout';

.mr-2 {
    margin-right: 20px;
}

.form-error-message {
    margin-top: 0.25rem;
    font-size: 80%;
    color: #f86c6b;
}

.table-report-general {
    border: solid gray 1px;
}

.table-report-general td {
    padding-left: 5px !important;
    text-align: center !important;
}

.table-report-general th {
    text-align: center !important;
}

.multiselect-checkbox {
    margin-top: 3px;
    color: #181c32;
    line-height: 1.5;
    font-weight: 500;
}

.formik-error-message {
    color: darkred;
}

.table-fixhead          { overflow-y: auto; max-height: 40vh; }
.table-fixhead thead th { position: sticky; top: 0; }
th     { background:rgb(20, 13, 13); }

.map-slider-pane {
    .slide-pane__close, .slide-pane__title-wrapper {
        margin-left: 0 !important;
    }
}

.form-check-info:checked {
    background-color: #63c2de;
    border-color: #39b2d5;
}

.form-check-success:checked {
    background-color: #4dbd74;
    border-color: green;
}

.form-check-warning:checked {
    background-color: #ff0;
    border-color: yellow;
}

.form-check-orange:checked {
    background-color: #ff8000;
    border-color: orange;
}

.form-check-danger:checked {
    background-color: red;
    border-color: red;
}

._loading_overlay_overlay {
    z-index: 0;
    background-color: rgba( #ccc, 0.80);
}

.images-scroll {
    overflow-x: scroll;
    overflow-y: hidden;
    max-height: 250px;
    white-space:nowrap
}

.image-item {
    box-shadow: 1px 1px 10px #999; 
    margin: 2px;
    cursor: pointer;
    max-height: 190px;
    display:inline-block;
    vertical-align:top;
    padding: 8px;
}