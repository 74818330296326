.rbc-time-content {
    visibility: hidden;
}

.rbc-time-header {
    min-height: auto;
    height: 75vh;
    margin-right: 0 !important;
}
.fila-reparacion-validar {
    background-color: red;
    color: red;
}
.rbc-time-header-gutter{
    width: 0 !important;
    min-width: 0 !important;
    max-width: 0 !important;
    padding-left: 0 !important;
}

.rbc-label {
    padding: 0 !important;
}

.rbc-event {
    background-color: unset !important;
    color: black;
}

.rbc-row-content {
    max-height: 72vh !important;
    overflow-y: auto !important;
}

.wrap-word {
    overflow-wrap: break-word;
    white-space: initial;
}

.bg-orange {
    background-color: rgb(255, 128, 0) !important;
}

.bg-yellow {
    background-color: rgb(255, 255, 0) !important;
}

.bg-red {
    background-color: rgb(255, 0, 0) !important;
}
